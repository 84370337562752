import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import 'styles/global.scss'
import { AuthProvider } from 'react-oidc-context'
import { OAUTH_CONFIG } from 'core/configs/auth'
import { RouterProvider } from 'react-router-dom'
import { appRouter } from 'router'
import { RecoilRoot } from 'recoil'

if (window.location.pathname === '/' && window.location.search.includes('add_journal')) {
  window.location.href = window.location.origin + '/home/dashboard?modal=add_journal'
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <RecoilRoot>
      <AuthProvider {...OAUTH_CONFIG}>
        <RouterProvider router={appRouter} />
      </AuthProvider>
    </RecoilRoot>
  </React.StrictMode>
)
