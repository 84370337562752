import ArrowLeft        from 'assets/icons/arrow-left.svg'
import ArrowRight       from 'assets/icons/arrow-right.svg'
import CalendarEvent    from 'assets/icons/calendar-event.svg'
import ChevronsDown     from 'assets/icons/chevrons-down.svg'
import CornerArrowDown  from 'assets/icons/corner_arrow_down.svg'
import Cross            from 'assets/icons/cross.svg'
import Edit             from 'assets/icons/edit.svg'
import FinalUp          from 'assets/icons/final_up.svg'
import Plus             from 'assets/icons/plus.svg'
import Trash            from 'assets/icons/trash.svg'
import CaretDown        from 'assets/icons/caret-down.svg'
import ChevronsUp       from 'assets/icons/chevrons-up.svg'
import CornerArrowUp    from 'assets/icons/corner_arrow_up.svg'
import DotsVertical     from 'assets/icons/dots-vertical.svg'
import FinalDown        from 'assets/icons/final_down.svg'
import Settings         from 'assets/icons/settings.svg'
import Share            from 'assets/icons/share.svg'
import Link             from 'assets/icons/link.svg'
import Users            from 'assets/icons/users.svg'
import Eye              from 'assets/icons/eye.svg'
import EyeOff           from 'assets/icons/eye-off.svg'
import MenuHamburger    from 'assets/icons/menu-hamburger.svg'
import LogOut           from 'assets/icons/log-out.svg'
import ArrowSimple      from 'assets/icons/arrow-simple.svg'
import Download         from 'assets/icons/download.svg'
import Dashboard        from 'assets/icons/dashboard.svg'
import Table            from 'assets/icons/table.svg'
import ZoomIn           from 'assets/icons/zoomin.svg'
import Note             from 'assets/icons/note.svg'
import ChartCandlestick from 'assets/icons/chart-candlestick.svg'

const ICONS_MAP = {
  ArrowLeft,
  ArrowRight,
  CalendarEvent,
  ChevronsDown,
  CornerArrowDown,
  Cross,
  Edit,
  FinalUp,
  Plus,
  Trash,
  CaretDown,
  ChevronsUp,
  CornerArrowUp,
  DotsVertical,
  FinalDown,
  Settings,
  Share,
  Link,
  Users,
  Eye,
  EyeOff,
  MenuHamburger,
  LogOut,
  ArrowSimple,
  Download,
  Dashboard,
  Table,
  ZoomIn,
  Note,
  ChartCandlestick
}

export default ICONS_MAP
