import { AllHTMLAttributes, ReactNode, useCallback, useEffect, useState } from 'react'
import './Filters.scss'
import { DateInput } from 'shared/DateInput/DateInput'

import imgPlus from 'assets/icons/plus.svg'
import SimpleDropdown from 'shared/Dropdown/SimpleDropdown/SimpleDropdown'
import { useNavigate } from 'react-router-dom'
import { JournalInfo } from 'core/types'
import MultiselectDropdown from 'shared/Dropdown/MultiselectDropdown/MultiselectDropdown'
import { useJournalsApi } from 'core/contexts/JournalsApiContext/JournalsApiContext'
import classNames from 'classnames'
import { useGlobalTimezone } from 'core/contexts/GlobalTimezoneContext'
import { Button } from 'shared/Button'
import { IconsSrc } from 'core/assets'
import { useJournals, useJournalsSharedWithMe } from 'core/api/journals'
import { useJournalsAll } from 'core/hooks/useJournalsAll'
import { useUserInfo } from 'core/api/common'
import { useTooltipText } from './hooks'
import { useRecoilState } from 'recoil'
import { filterState } from 'core/store'
import Menu from 'shared/Menu'
import Icon from 'shared/Icon'
import { Modal, useModal } from 'shared/Modal'
import { CreateTradeModal } from './modals/CreateTradeModal/CreateTradeModal'
import { useAliases } from 'core/api/dashboard'

type FiltersProps = AllHTMLAttributes<HTMLDivElement>
/** Section with date & time filters */
export function Filters({ className, ...props }: Readonly<FiltersProps>) {
  const [filters, setFilters] = useRecoilState(filterState)
  const { openAddDialog, openShareDialog } = useJournalsApi()
  const userInfo = useUserInfo()
  const journals = useJournals()
  const journalsShared = useJournalsSharedWithMe()
  const journalsAll = useJournalsAll()

  const aliasesAll = useAliases()

  const navigate = useNavigate()

  const applyJournal = (journalToApply: JournalInfo | null) => {
    if (journalToApply === null) {
      if (!filters.journalId === null) return
      setFilters((filters) => ({ ...filters, journalId: null }))
      return
    }
    if (journalToApply.id === filters.journalId) return
    setFilters((filters) => ({ ...filters, journalId: journalToApply.id }))
  }

  const { dateFormatter } = useGlobalTimezone()

  const [selectedJournal, setSelectedJournal] = useState<JournalInfo | null>(null)
  useEffect(() => {
    if (selectedJournal === null && filters.journalId === null) return
    if (selectedJournal?.id === filters.journalId) return
    if (journalsAll.isLoading || journalsShared.isLoading) return

    const shouldBeSelected = journalsAll.data?.find((journal) => journal.id === filters.journalId)
    if (shouldBeSelected) {
      setSelectedJournal(shouldBeSelected)
      return
    }
    const shouldBeSelectedShared = journalsShared.data?.find(
      (journal) => journal.id === filters.journalId
    )
    if (shouldBeSelectedShared) {
      setSelectedJournal(shouldBeSelectedShared)
      return
    }
    // Reset filter to null if journal does not exist
    setFilters((filters) => ({ ...filters, journalId: null }))
    if (selectedJournal) setSelectedJournal(null)
  }, [
    filters.journalId,
    journalsAll.data,
    journalsAll.isLoading,
    journalsShared.data,
    journalsShared.isLoading,
    selectedJournal,
    setFilters,
  ])

  const {
    selectedInstrumentsTooltipContent,
    selectedJournalTooltipContent,
    shareButtonTooltipContent,
  } = useTooltipText(selectedJournal)

  const createTradeModal = useModal()

  const journalsGetVisualValue = useCallback(
    (journal: JournalInfo | null): ReactNode => {
      if (!journal) return 'All my journals'
      if (journal.owner.userEmail === userInfo.data?.userEmail) return journal.journalName
      return (
        <div className="Filters__inputs__journal-content">
          <img src={IconsSrc.Share} alt="Text" /> {journal.journalName}
        </div>
      )
    },
    [userInfo.data?.userEmail]
  )

  return (
    <>
      <div className={classNames('Filters', className || '')} {...props}>
        <div className="Filters__inputs">
          <SimpleDropdown<JournalInfo | null>
            className="input"
            value={selectedJournal}
            onChange={applyJournal}
            tooltipContent={selectedJournalTooltipContent}
            getVisualValue={journalsGetVisualValue}
          >
            <SimpleDropdown.Item value={null}>
              <SimpleDropdown.ItemText
                primary="All my journals"
                secondary={
                  (journals.data?.length ?? 1) +
                  ` journal${journals.data?.length === 1 ? '' : 's'} owned`
                }
              />
            </SimpleDropdown.Item>
            {journalsShared.data?.length !== 0 && (
              <div className="Filters__input-mid-item-divider">My journals</div>
            )}
            {journals.data
              ? journals.data.map((journal) => (
                  <SimpleDropdown.Item value={journal} key={journal.id}>
                    <SimpleDropdown.ItemText
                      primary={journal.journalName}
                      secondary={
                        journal.updateDate
                          ? 'Updated on ' + dateFormatter.format(new Date(journal.updateDate))
                          : '-'
                      }
                    />
                  </SimpleDropdown.Item>
                ))
              : null}
            {journalsShared.data?.length !== 0 && (
              <div className="Filters__input-mid-item-divider">Shared with me</div>
            )}
            {journalsShared.data
              ? journalsShared.data.map((journal) => (
                  <SimpleDropdown.Item value={journal} key={journal.id}>
                    <SimpleDropdown.ItemText
                      primary={journal.journalName}
                      secondary={
                        journal.updateDate
                          ? 'Updated on ' + dateFormatter.format(new Date(journal.updateDate))
                          : '-'
                      }
                    />
                  </SimpleDropdown.Item>
                ))
              : null}
            <SimpleDropdown.FooterSection>
              <SimpleDropdown.Divider />
              <SimpleDropdown.ActionItem onClick={() => openAddDialog()}>
                <img src={imgPlus} alt="Add" /> Add journal
              </SimpleDropdown.ActionItem>
            </SimpleDropdown.FooterSection>
          </SimpleDropdown>
          <MultiselectDropdown
            className="input"
            value={filters.symbols}
            allValues={aliasesAll.data?.result ?? []}
            placeholder="All Instruments"
            tooltipContent={selectedInstrumentsTooltipContent}
            onChange={(sy) => setFilters((v) => ({ ...v, symbols: sy }))}
          ></MultiselectDropdown>

          <div className="label">from</div>
          <DateInput
            className="input"
            value={filters.dateFrom}
            onChange={(date) => setFilters((filters) => ({ ...filters, dateFrom: date }))}
          />
          <div className="label">to</div>
          <DateInput
            className="input"
            value={filters.dateTo}
            onChange={(date) => setFilters((filters) => ({ ...filters, dateTo: date }))}
          />
        </div>

        <div className="Filters__buttons">
          <Menu
            trigger={
              <Button appearance="solid" className="important">
                <Icon name="Plus" /> New
              </Button>
            }
          >
            <Menu.Item onClick={() => openAddDialog()}>Journal</Menu.Item>
            <Menu.Item onClick={() => createTradeModal.open()}>Trade</Menu.Item>
          </Menu>
          <Button
            appearance="outline"
            tooltipContent="Manage your journals"
            onClick={() => navigate('/manage-journals')}
          >
            <Icon name="Settings" />
            <span className="text">Manage</span>
          </Button>

          <Button
            appearance="outline"
            color="secondary"
            disabled={
              !selectedJournal || selectedJournal?.owner.userEmail !== userInfo.data?.userEmail
            }
            onClick={() => selectedJournal && openShareDialog(selectedJournal.id)}
            tooltipContent={shareButtonTooltipContent}
          >
            <Icon name="Share" />
            <span className="text">Share</span>
          </Button>
        </div>
      </div>
      <Modal controller={createTradeModal}>
        <CreateTradeModal controller={createTradeModal} />
      </Modal>
    </>
  )
}
