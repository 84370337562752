import './App.scss'
import { Outlet, useNavigate } from 'react-router-dom'
import { Notifications } from 'shared/Notifications'
import { GlobalTimezoneContextProvider } from 'core/contexts/GlobalTimezoneContext'
import { JournalsApiContextProvider } from 'core/contexts/JournalsApiContext'
import { SWRConfig } from 'swr'
import { ApolloProvider } from '@apollo/client'
import { graphQlClient } from 'core/api/graphQlClient'
import { useEffect } from 'react'
import { LS_ACCESS_TOKEN, LS_RESTORE_PATH } from 'core/constants'

export function App() {
  const navigate = useNavigate()

  useEffect(() => {
    if (
      localStorage.getItem(LS_ACCESS_TOKEN) === null &&
      window.location.pathname !== '/login' &&
      window.location.pathname !== '/signup'
    ) {
      localStorage.setItem(LS_RESTORE_PATH, window.location.pathname + window.location.search)
      navigate('/login')
    }
  })

  return (
    <div className="App">
      <SWRConfig value={{ revalidateOnMount: true }}>
        <ApolloProvider client={graphQlClient}>
          <GlobalTimezoneContextProvider>
            <Notifications>
              <JournalsApiContextProvider>
                <Outlet />
              </JournalsApiContextProvider>
            </Notifications>
          </GlobalTimezoneContextProvider>
        </ApolloProvider>
      </SWRConfig>
    </div>
  )
}

export default App
