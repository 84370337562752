import { useTrades } from 'core/api/dashboard'
import { useSelectedJournal } from 'pages/HomePage/components/Filters/useSelectedJournal'
import { useUserInfo } from 'core/api/common'
import { useRecoilState } from 'recoil'
import { tradesTableState } from 'core/store/tradesTable'
import { filterState } from 'core/store'

export function useFilteredTrades() {
  const [filters] = useRecoilState(filterState)
  const [tradesTableSt] = useRecoilState(tradesTableState)
  return useTrades(filters, tradesTableSt)
}

export function useSelectedJournalIsLocked() {
  const selectedJournal = useSelectedJournal()
  const userInfo = useUserInfo()
  if (!selectedJournal) return false
  if (selectedJournal.typeEditable) return false
  return selectedJournal.owner.userEmail !== userInfo.data?.userEmail
}
