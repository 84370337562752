import { useUserInfo } from 'core/api/common'
import { filterState } from 'core/store'
import { JournalInfo } from 'core/types'
import { useEffect, useMemo, useState } from 'react'
import { useRecoilState } from 'recoil'

export const useScrolling = () => {
  const [isScrolled, setIsScrolled] = useState(false)

  useEffect(() => {
    const handleScroll = () => setIsScrolled(document.documentElement.scrollTop > 90)
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { isScrolled }
}

export const useTooltipText = (selectedJournal: JournalInfo | null) => {
  const userInfo = useUserInfo()
  const [filters] = useRecoilState(filterState)

  const shareButtonTooltipContent = useMemo(() => {
    if (!selectedJournal) return 'You need to select a journal'
    if (selectedJournal?.owner.userEmail !== userInfo.data?.userEmail)
      return 'You can only share your own journals'
    return 'Share selected journal with someone'
  }, [selectedJournal, userInfo.data?.userEmail])

  const selectedInstrumentsTooltipContent = useMemo(() => {
    if (filters.symbols.length === 0) return 'All instruments are selected'
    return 'Selected instruments: ' + filters.symbols.join(', ')
  }, [filters.symbols])

  const selectedJournalTooltipContent = useMemo(() => {
    if (!selectedJournal) return 'All journals are selected'
    return 'Selected journal: ' + selectedJournal.journalName
  }, [selectedJournal])

  return {
    shareButtonTooltipContent,
    selectedInstrumentsTooltipContent,
    selectedJournalTooltipContent,
  }
}
