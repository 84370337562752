import './MetricsPage.scss'

import { useState } from 'react'
import Card from 'shared/Card'
import { SimpleDropdown } from 'shared/Dropdown'
import PageLayout from 'shared/PageLayout'
import { Controller, useForm } from 'react-hook-form'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Rectangle,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts'
import Filters from 'pages/HomePage/components/Filters'
import { useQuery } from '@apollo/client'
import { useGetBarDiagramQuery } from 'core/api/widgets'
import {
  METRICS_AGGREGATIONS,
  METRICS_CLASSIFICATIONS,
  METRICS_PARAMETERS,
  MetricsCellConfig,
} from 'core/types'
import { useRecoilState } from 'recoil'
import { filterState } from 'core/store'

function useMetricsValues(config: MetricsCellConfig) {
  const [filters] = useRecoilState(filterState)

  const GQL_QUERY = useGetBarDiagramQuery(filters, config)
  const result = useQuery<{ getBarDiagram: { classifier: string; value: number }[] }>(GQL_QUERY)

  return result
}

function MetricsCell() {
  const [config, setConfig] = useState<MetricsCellConfig>({
    parameter: 'profit',
    aggregation: 'count',
    classification: 'timeInTrade',
  })
  const { control, watch } = useForm<MetricsCellConfig>({ defaultValues: config })
  watch((v) => {
    if (!v.aggregation || !v.classification || !v.parameter) return
    setConfig(v as MetricsCellConfig)
  })
  const { data, error } = useMetricsValues(config)

  const size = Math.max((data?.getBarDiagram.length ?? 1) * 24, 500)

  return (
    <Card className="MetricsCell">
      <div className="header">
        <div className="name">Widget Name</div>
        <form className="params">
          <Controller
            control={control}
            name="parameter"
            render={({ field }) => (
              <SimpleDropdown onChange={field.onChange} onBlur={field.onBlur} value={field.value}>
                {METRICS_PARAMETERS.map((v) => (
                  <SimpleDropdown.Item key={v} value={v}>
                    {v}
                  </SimpleDropdown.Item>
                ))}
              </SimpleDropdown>
            )}
          />

          <Controller
            control={control}
            name="aggregation"
            render={({ field }) => (
              <SimpleDropdown onChange={field.onChange} onBlur={field.onBlur} value={field.value}>
                {METRICS_AGGREGATIONS.map((v) => (
                  <SimpleDropdown.Item key={v} value={v}>
                    {v}
                  </SimpleDropdown.Item>
                ))}
              </SimpleDropdown>
            )}
          />

          <Controller
            control={control}
            name="classification"
            render={({ field }) => (
              <SimpleDropdown onChange={field.onChange} onBlur={field.onBlur} value={field.value}>
                {METRICS_CLASSIFICATIONS.map((v) => (
                  <SimpleDropdown.Item key={v} value={v}>
                    {v}
                  </SimpleDropdown.Item>
                ))}
              </SimpleDropdown>
            )}
          />
        </form>
      </div>
      <div className="chart-section">
        <div className="chart">
          {data && (
            <ResponsiveContainer width="100%" height={size}>
              <BarChart
                data={data.getBarDiagram}
                layout="vertical"
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <Bar
                  dataKey="value"
                  fill="#8884d8"
                  activeBar={<Rectangle fill="pink" stroke="blue" />}
                />
                <Tooltip />
                <YAxis dataKey="classifier" type="category" width={100} />
                <XAxis dataKey="value" type="number" scale="sqrt" />
                <ReferenceLine x={0} stroke="#f00" />
                <CartesianGrid strokeDasharray="1" stroke="#444" />
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>

        {data && (
          <div className="value-grid">
            {data.getBarDiagram.map((v, index) => (
              <div key={index + 'a'}>
                <div>{v.classifier}</div>
                <div>{v.value}</div>
              </div>
            ))}
          </div>
        )}

        {error && <div className="error">Error: {JSON.stringify(error)}</div>}
      </div>
    </Card>
  )
}

export function MetricsPage() {
  return (
    <PageLayout className="MetricsPage">
      <PageLayout.Header />
      <PageLayout.Content>
        <h2>Metrics</h2>
        <Filters />
        <div className="grid">
          <MetricsCell />
          {/* <MetricsCell /> */}
        </div>
      </PageLayout.Content>
      <PageLayout.Footer />
    </PageLayout>
  )
}
