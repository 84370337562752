import './NoteSection.scss'

import { Note } from 'core/types'
import { NoteText } from './NoteText/NoteText'
import { NoteMedia } from './NoteMedia/NoteMedia'
import { NoteSectionContextProvider } from './NoteSectionContext'

type NoteSectionProps = {
  note?: Note
  onNoteCreate: () => Promise<Note>
  onUpdate: () => Promise<unknown>
}
export function NoteSection({ note, onNoteCreate, onUpdate }: NoteSectionProps) {
  // console.log(note)
  return (
    <NoteSectionContextProvider note={note} onNoteCreate={onNoteCreate} onUpdate={onUpdate}>
      <div className="NoteSection">
        <div className="header text-normal-bold">Note</div>
        <NoteText />
        <NoteMedia />
      </div>
    </NoteSectionContextProvider>
  )
}
