import { FilterState } from 'core/contexts/FilterContext'
import { AtomEffect } from 'recoil'

export const localStorageEffect =
  (key: string): AtomEffect<FilterState> =>
  ({ setSelf, onSet }) => {
    /* ON INIT */
    const savedValue = localStorage.getItem(key)
    if (savedValue != null) {
      const parsedValue = JSON.parse(savedValue)

      try {
        const newState: FilterState = {
          dateFrom: new Date(parsedValue.dateFrom),
          dateTo: new Date(parsedValue.dateTo),
          symbols: parsedValue.symbols,
          journalId: parsedValue.journalId || null,
        }
        if (isNaN(newState.dateFrom.getTime()) || isNaN(newState.dateTo.getTime())) {
          throw new Error('Invalid date')
        }

        setSelf(newState)
      } catch (e) {
        console.error('Failed to parse saved value', e)
      }
    }

    /* ON CHANGE */
    onSet((newValue, _, isReset) => {
      if (isReset) {
        localStorage.removeItem(key)
      } else {
        console.log('[RECOIL EFFECT] write value', newValue, JSON.stringify(newValue))
        localStorage.setItem(key, JSON.stringify(newValue))
      }
    })
  }
