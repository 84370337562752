import { UploadingItem } from '../useAttachmentUploading.hook'
import './MediaUploadingStatus.scss'

type MediaUploadingIndicatorProps = { uploadingList: UploadingItem[] }
export function MediaUploadingStatus({ uploadingList }: MediaUploadingIndicatorProps) {
  if (!uploadingList.length) return null
  return (
    <div className="MediaUploadingStatus">
      {uploadingList.map((uploadingItem) => (
        <Loader
          key={uploadingItem.id}
          fileName={uploadingItem.file?.name ?? 'N/A'}
          progress={uploadingItem.progress}
        />
      ))}
    </div>
  )
}

type AttachmentLoaderProps = { fileName: string; progress?: number | null }
export function Loader({ fileName, progress }: AttachmentLoaderProps) {
  return (
    <div className="Loader">
      <div className="text">
        <div className="file-name">{fileName}</div>
        <div className="file-status">
          Uploading... {progress != null && Math.round(progress) + '%'}
        </div>
      </div>
      <div className="progress">
        <div
          className="progress-bar"
          style={{ width: progress != null ? progress + '%' : 0 }}
        ></div>
      </div>
    </div>
  )
}
