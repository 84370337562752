import { API_URL, LS_DASHBOARD_SORT_PARAMS, LS_TRADES_TABLE_COLUMNS } from 'core/constants'
import { useEffect, useMemo, useState } from 'react'
import { useNotifications } from 'shared/Notifications'
import { appFetch, HttpError } from 'core/utils'
import { TradeItem } from 'core/types'
import { getInitialTradesTableKeys } from './utils'
import {
  TABLE_PAGE_SIZE,
  TRADES_TABLE_AVAILABLE_HEADERS_MAP,
  TRADES_TABLE_SORTING_DEFAULTS,
} from './constants'
import { AllowedTradeKeys, TradesTableHeader } from './models'
import { PagingParams, SortingParams } from 'core/api/dashboard'
import useUpdateHomePageData from 'pages/HomePage/hooks/useUpdateHomePageData'

type UseTradesTableHeadersHookResponse = {
  columns: TradesTableHeader[]
  setColumnsKeys: React.Dispatch<React.SetStateAction<AllowedTradeKeys[]>>
  isColumSelectedMap: Record<AllowedTradeKeys, boolean>
}
export function useTradesTableHeaders(): UseTradesTableHeadersHookResponse {
  const [columnsKeys, setColumnsKeys] = useState<AllowedTradeKeys[]>(() =>
    getInitialTradesTableKeys()
  )
  const columns: TradesTableHeader[] = useMemo(
    () => columnsKeys.map((key) => TRADES_TABLE_AVAILABLE_HEADERS_MAP[key]),
    [columnsKeys]
  )
  const isColumSelectedMap: Record<AllowedTradeKeys, boolean> = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(TRADES_TABLE_AVAILABLE_HEADERS_MAP).map(([key, _]) => [
          key,
          columnsKeys.includes(key as AllowedTradeKeys),
        ])
      ) as Record<AllowedTradeKeys, boolean>,
    [columnsKeys]
  )

  useEffect(() => {
    localStorage.setItem(LS_TRADES_TABLE_COLUMNS, JSON.stringify(columnsKeys))
  }, [columnsKeys])

  return { columns, setColumnsKeys, isColumSelectedMap }
}

export function useTradesTableApi() {
  const [isLoading, setIsLoading] = useState(false)
  const { showNotification } = useNotifications()

  const updateHomePage = useUpdateHomePageData()

  const deleteTrade = async (tradeId: string) => {
    setIsLoading(true)
    try {
      await appFetch(API_URL + `/api/dashboard/trades/${tradeId}`, 'DELETE')
      await updateHomePage()
      showNotification({ text: 'Trade deleted successfully', type: 'success' })
    } catch (err) {
      if (err instanceof HttpError) {
        if (err.status === 406) {
          return showNotification({
            text: "Journal type doesn't support trades deletion",
            type: 'error',
          })
        } else if (err.status === 404) {
          return showNotification({ text: 'Trade does not exist', type: 'error' })
        } else {
          return showNotification({ text: 'Failed to delete trade', type: 'error' })
        }
      } else {
        console.error(err)
        showNotification({ text: 'Unknown error', type: 'error' })
      }
      throw err
    } finally {
      setIsLoading(false)
    }
  }

  const moveTrade = async (tradeId: string, journalId: string, journalName?: string) => {
    setIsLoading(true)
    try {
      await appFetch(API_URL + `/api/dashboard/trades/moveTo/${journalId}`, 'PUT', [tradeId])
      await updateHomePage()
      const msg = journalName ? ` to ${journalName}` : ' another journal'
      showNotification({
        text: 'Trade moved successfully' + msg,
        type: 'success',
      })
    } catch (err) {
      showNotification({ text: 'Failed to move trade', type: 'error' })
    } finally {
      setIsLoading(false)
    }
  }

  return { isLoading, deleteTrade, moveTrade }
}

function getInitialSortParams() {
  const savedSortParams = localStorage.getItem(LS_DASHBOARD_SORT_PARAMS)
  return savedSortParams ? JSON.parse(savedSortParams) : TRADES_TABLE_SORTING_DEFAULTS
}

export function useTradesTablePagingSortingParams() {
  const [paginationPage, setPaginationPage] = useState<number>(0)
  const [sortParams, setSortParams] = useState<SortingParams<TradeItem>>(getInitialSortParams)

  useEffect(() => {
    localStorage.setItem(LS_DASHBOARD_SORT_PARAMS, JSON.stringify(sortParams))
  }, [sortParams])

  const pagingSortingParams = useMemo(
    () =>
      ({
        page: paginationPage,
        size: TABLE_PAGE_SIZE,
        ...sortParams,
      } satisfies PagingParams & SortingParams<TradeItem>),
    [paginationPage, sortParams]
  )

  return { pagingSortingParams, paginationPage, setPaginationPage, setSortParams, sortParams }
}
