import { FilterState } from 'core/contexts/FilterContext'
import { atom, selector } from 'recoil'
import { localStorageEffect } from './filter.effects'
import { MS_IN_HOUR } from 'core/constants'
import { add } from 'date-fns'

export const filterState = atom<FilterState>({
  key: 'filterState',
  default: {
    dateFrom: roundDateToHours(add(new Date(), { years: -1 })),
    dateTo: roundDateToHours(add(new Date(), { months: 1 })),
    symbols: [],
    journalId: null,
  },
  effects: [localStorageEffect('filterState')],
})

export const filterQueryParamsSelector = selector<URLSearchParams>({
  key: 'filterQueryParamsSelector',
  get: ({ get }) => {
    const filters = get(filterState)

    return new URLSearchParams({
      ...(filters.journalId && { journalId: filters.journalId }),
      ...(filters.symbols.length > 0 && { symbols: filters.symbols.join(',') }),
      ...(filters.dateFrom && { fromTime: filters.dateFrom.toISOString() }),
      ...(filters.dateTo && { toTime: filters.dateTo.toISOString() }),
    })
  },
})

function roundDateToHours(date: Date) {
  return new Date(date.getTime() - (date.getTime() % MS_IN_HOUR))
}
