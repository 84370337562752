import { API_URL } from 'core/constants'
import httpClient from './httpClient'
import { JournalDetailedInfo, JournalInfo, JournalType, Note } from 'core/types'
import useSWR from 'swr'
import { httpClientFetcher } from './httpClientFetcher'
import { useMemo } from 'react'

const REFRESH_INTERVAL = 1000 * 60 * 15

const genericGet: <T>(url: string) => Promise<T> = async <T>(url: string) =>
  (await httpClient.get<{ result: T }>(API_URL + url)).data.result

export const getJournals = async () => genericGet<JournalInfo[]>('/api/dashboard/journals')
export const useJournals = () =>
  useSWR('/api/dashboard/journals', getJournals, { refreshInterval: REFRESH_INTERVAL })

export const getJournalTypes = async () =>
  genericGet<JournalType[]>('/api/dashboard/journals/types')
export const useJournalTypes = () =>
  useSWR('/api/dashboard/journals/types', getJournalTypes, { refreshInterval: REFRESH_INTERVAL })

export const getJournalsSharedByMe = async () =>
  genericGet<JournalInfo[]>('/api/dashboard/journals/shared')
export const useJournalsSharedByMe = () =>
  useSWR('/api/dashboard/journals/shared', getJournalsSharedByMe, {
    refreshInterval: REFRESH_INTERVAL,
  })

export const getJournalsSharedWithMe = async () =>
  genericGet<JournalInfo[]>('/api/dashboard/journals/sharedWithMe')
export const useJournalsSharedWithMe = () =>
  useSWR('/api/dashboard/journals/sharedWithMe', getJournalsSharedWithMe, {
    refreshInterval: REFRESH_INTERVAL,
  })

export const getJournalsDetailed = async () =>
  genericGet<JournalDetailedInfo[]>('/api/dashboard/journals/detailed')
export const useJournalsDetailed = () =>
  useSWR('/api/dashboard/journals/detailed', getJournalsDetailed, {
    refreshInterval: REFRESH_INTERVAL,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  })

export const getJournalsSharedWithMeDetailed = async () =>
  genericGet<JournalDetailedInfo[]>('/api/dashboard/journals/sharedWithMe/detailed')
export const useJournalsSharedWithMeDetailed = () =>
  useSWR('/api/dashboard/journals/sharedWithMe/detailed', getJournalsSharedWithMeDetailed, {
    refreshInterval: REFRESH_INTERVAL,
  })

export const getContactsSharedForJournal = async (journalId: string) =>
  genericGet<string[]>(`/api/dashboard/journals/${journalId}/sharedWith`)
export const useContactsSharedForJournal = (journalId: string) =>
  useSWR(
    `/api/dashboard/journals/${journalId}/sharedWith`,
    () => getContactsSharedForJournal(journalId),
    {
      refreshInterval: REFRESH_INTERVAL,
    }
  )

export const deleteJournal = async (journalId: string) =>
  (await httpClient.delete(API_URL + `/api/dashboard/journals/${journalId}`)).data

export const shareJournal = async (journalId: string, contacts: string[]) =>
  (await httpClient.post(API_URL + `/api/dashboard/journals/${journalId}/shareWith`, contacts)).data

export const unshareJournalForUsers = async (journalId: string, contacts: string[]) =>
  (
    await httpClient.delete(
      API_URL + `/api/dashboard/journals/${journalId}/shareWith?userEmails=${contacts.join(',')}`
    )
  ).data

export const unshareJournalForMe = async (journalId: string) =>
  (await httpClient.delete(API_URL + `/api/dashboard/journals/sharedWithMe/${journalId}`)).data

export const addJournal = async (journalName: string, journalType: string) => {
  const response = await httpClient.post<{ result: JournalInfo }>(
    API_URL + '/api/dashboard/journals',
    { journalName, journalType }
  )
  return response.data.result
}

export const editJournal = async (journalId: string, journalName: string, journalType: string) =>
  (
    await httpClient.put<{ result: JournalInfo }>(
      API_URL + `/api/dashboard/journals/${journalId}`,
      {
        journalName,
        journalType,
      }
    )
  ).data.result

export const URL_GET_NOTES_FOR_JOURNAL = (journalId: string) =>
  API_URL + `/api/dashboard/journals/${journalId}/notes?includeJournalNotes=true`
export const getNotesForJournal = async (journalId: string) => {
  return httpClient.get<{ result: unknown }>(URL_GET_NOTES_FOR_JOURNAL(journalId))
}
export const useNotesForJournal = (journalId: string | null) =>
  useSWR(
    journalId ? URL_GET_NOTES_FOR_JOURNAL(journalId) : null,
    httpClientFetcher<{ result: unknown }>
  )

type UseNotesParams = {
  journalIds?: string[]
  includeTradeNotes?: boolean
  includeJournalNotes?: boolean
  page?: number
  size?: number
  sort?: keyof Note
}
type UseNotesResponse = {
  result: Note[]
  pageInfo: {
    totalPages: number
    totalElements: number
  }
}
export const useNotes = (params: UseNotesParams) => {
  const { journalIds, includeTradeNotes, includeJournalNotes, page, size, sort } = params
  const paramsSerialized: Partial<Record<keyof UseNotesParams, string>> = useMemo(
    () => ({
      ...(includeJournalNotes && { includeJournalNotes: includeJournalNotes ? 'true' : 'false' }),
      ...(includeTradeNotes && { includeTradeNotes: includeTradeNotes ? 'true' : 'false' }),
      ...(journalIds && journalIds?.length > 0 && { journalIds: journalIds.join(',') }),
      ...(page && { page: page.toString() }),
      ...(size && { size: size.toString() }),
      ...(sort && { sort: sort }),
    }),
    [includeJournalNotes, includeTradeNotes, journalIds, page, size, sort]
  )
  const searchParams = new URLSearchParams(paramsSerialized).toString()

  const url =
    API_URL + '/api/dashboard/journals/notes' + (searchParams.length ? `?${searchParams}` : '')
  // console.log('url', url)
  return useSWR(url, httpClientFetcher<UseNotesResponse>)
}
