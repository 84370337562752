import { useRef, useState } from 'react'
import { ModalConfig, ModalController } from './models'

export function useModal<T = void, D = void>({
  onBackdropClick,
}: ModalConfig<T> = {}): ModalController<T, D> {
  const [isOpen, setIsOpen] = useState(false)
  const [data, setData] = useState<D | undefined>(undefined)
  const resolveRef = useRef<((value?: T) => void) | null>(null)

  const open = (payload?: D) => {
    setData(payload)
    setIsOpen(true)
    return new Promise<T | void>((resolve) => {
      resolveRef.current = resolve
    })
  }

  const close = (result?: T) => {
    setIsOpen(false)
    resolveRef.current?.(result)
  }

  const handleBackdropClick = () => {
    onBackdropClick ? onBackdropClick() : close()
  }

  const resolveWithoutClosing = (value: T) => {
    resolveRef.current?.(value)
  }

  return {
    isOpen,
    data,
    open,
    close,
    resolveWithoutClosing,
    onBackdropClick: handleBackdropClick,
  }
}
