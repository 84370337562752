import { Button } from 'shared/Button'
import './NoteMediaHeader.scss'
import { SimpleDropdown } from 'shared/Dropdown'

type NoteMediaHeaderProps = {
  sort: 'asc' | 'desc'
  setSort: (value: 'asc' | 'desc') => void
  sortingDisabled: boolean
  onBrowseClick: () => void
}
export function NoteMediaHeader({
  sort,
  setSort,
  sortingDisabled,
  onBrowseClick,
}: NoteMediaHeaderProps) {
  return (
    <div className="NoteMediaHeader">
      <div className="message">
        Drop file here or{' '}
        <Button
          appearance="link"
          color="primary"
          size="small"
          className="browse-button"
          onClick={onBrowseClick}
        >
          browse
        </Button>
      </div>

      <SimpleDropdown
        value={sort}
        onChange={setSort}
        getVisualValue={(value) => <SortingDropdownContent value={value} />}
        disabled={sortingDisabled}
      >
        <SimpleDropdown.Item value={'asc'}>Old first</SimpleDropdown.Item>
        <SimpleDropdown.Item value={'desc'}>New first</SimpleDropdown.Item>
      </SimpleDropdown>
    </div>
  )
}

type SortingDropdownContentProps = { value: 'asc' | 'desc' }
function SortingDropdownContent({ value }: SortingDropdownContentProps) {
  return (
    <div className="SortingDropdownContent">
      <span className="color-secondary-l3">Sort:</span>
      <span>{value === 'asc' ? 'Old first' : 'New first'}</span>
    </div>
  )
}
