import { PagingParams, SortingParams } from 'core/api/dashboard'
import { TradeItem } from 'core/types'
import { atom } from 'recoil'
import { localStorageEffect } from './tradesTable.effects'

export type TradesTableState = PagingParams &
  SortingParams<TradeItem> & {
    selectedTradeId: string | null | undefined
  }

export const tradesTableState = atom<TradesTableState>({
  key: 'tradesTableState',
  default: {
    page: 1,
    size: 10,
    sort: 'timeEntry',
    order: 'desc',
    selectedTradeId: null,
  },
  effects: [localStorageEffect('tradesTableState')],
})
