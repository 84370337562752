import './TradeInfoGridFull.scss'

import { TradeItem } from 'core/types'
import { durationToHumanReadable } from 'core/utils'
import { AllHTMLAttributes, useMemo } from 'react'
import Chip from 'shared/Chip'
import InfoTooltip from 'shared/InfoTooltip'
import TextDate from 'shared/TextDate'
import TextMoney from 'shared/TextMoney'

type TradeInfoGridFullProps = { tradeItem: TradeItem } & AllHTMLAttributes<HTMLDivElement>
export function TradeInfoGridFull({ tradeItem, ...props }: TradeInfoGridFullProps) {
  const { dateEntry, dateExit, durationText } = useTableInfoGridData(tradeItem)

  return (
    <div className="TradeInfoGridFull" {...props}>
      <TradeInfoGridCell label="Entry date & time">
        <TextDate date={dateEntry} />
      </TradeInfoGridCell>
      <TradeInfoGridCell label="Exit date & time">
        <TextDate date={dateExit} />
      </TradeInfoGridCell>
      <TradeInfoGridCell label="Time in trade">{durationText}</TradeInfoGridCell>

      <TradeInfoGridCell label="Instrument">{tradeItem.instrumentAlias}</TradeInfoGridCell>
      <TradeInfoGridCell label="Max size">{tradeItem.maxSize}</TradeInfoGridCell>
      <TradeInfoGridCell label="Commissions">
        <TextMoney value={tradeItem.commission} />
      </TradeInfoGridCell>

      <TradeInfoGridCell label="Entry price">
        <TextMoney value={tradeItem.priceEntry} />
      </TradeInfoGridCell>
      <TradeInfoGridCell label="Exit price">
        <TextMoney value={tradeItem.priceExit} />
      </TradeInfoGridCell>
      <TradeInfoGridCell label="Profit">
        <TextMoney value={tradeItem.profit} colored />
      </TradeInfoGridCell>

      <TradeInfoGridCell label="Type">
        {tradeItem.isBuy ? <Chip color="green">Buy</Chip> : <Chip color="red">Sell</Chip>}
      </TradeInfoGridCell>
      <div className="double-cell">
        <TradeInfoGridCell label="MFE">{tradeItem.mfe}</TradeInfoGridCell>
        <TradeInfoGridCell label="MAE">{tradeItem.mae}</TradeInfoGridCell>
      </div>
      <div className="double-cell">
        <TradeInfoGridCell label="Post MFE">{tradeItem.postMfe ?? 'N/A'}</TradeInfoGridCell>
        <TradeInfoGridCell label="Post MAE">{tradeItem.postMae ?? 'N/A'}</TradeInfoGridCell>
      </div>
    </div>
  )
}

type TradeInfoGridCellProps = { label: string; tooltipText?: string; children: React.ReactNode }
export function TradeInfoGridCell({
  children,
  label,
  tooltipText,
}: Readonly<TradeInfoGridCellProps>) {
  return (
    <div className="TradeInfoGridCell">
      <div className="label color-secondary-l3 text-description-regular">
        {label} {tooltipText && <InfoTooltip>tooltipText</InfoTooltip>}
      </div>
      <div className="value">{children}</div>
    </div>
  )
}

export function useTableInfoGridData(selectedTrade: TradeItem | null) {
  return useMemo(() => {
    const dateEntry = new Date(selectedTrade?.timeEntry ?? 0)
    const dateExit = new Date(selectedTrade?.timeExit ?? 0)
    const durationText =
      selectedTrade?.timeEntry && selectedTrade?.timeExit
        ? durationToHumanReadable(Math.abs(dateExit.getTime() - dateEntry.getTime()))
        : 'N/A'

    return {
      dateEntry,
      dateExit,
      durationText,
    }
  }, [selectedTrade])
}
