import { RefObject, useCallback, useEffect, useState } from 'react'

export function useTrackImgElementLoading(ref: RefObject<HTMLImageElement>) {
  const [isLoading, setIsLoading] = useState(false)

  const handleLoad = useCallback(() => setIsLoading(false), [])
  const handleError = useCallback(() => setIsLoading(false), [])

  useEffect(() => {
    const img = ref.current
    if (!img) return
    if (img.complete) return setIsLoading(false)

    setIsLoading(true)
    img.addEventListener('load', handleLoad)
    img.addEventListener('error', handleError)

    return () => {
      if (!img) return
      img.removeEventListener('load', handleLoad)
      img.removeEventListener('error', handleError)
    }
  }, [ref, handleLoad, handleError])

  return isLoading
}
