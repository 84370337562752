import { LS_ACCESS_TOKEN } from 'core/constants'
import { gql } from '@apollo/client'
import { FilterState } from 'core/contexts/FilterContext'

export function makeDateConsumableForApi(date: Date): string {
  return encodeURI(date.toISOString()).split('.')[0]
}

export const INITIAL_SWR_DATA = {
  data: undefined,
  error: undefined,
  isValidating: false,
  mutate: () => Promise.resolve() as Promise<undefined>,
  isLoading: true,
}

export const appFetch = async <T = any>(
  url: URL | string,
  method: string,
  body?: Record<string, any> | string | string[]
): Promise<T> => {
  const token = localStorage.getItem(LS_ACCESS_TOKEN)
  try {
    const response = await fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json',
        ...(token && { Authorization: `Bearer ${token}` }),
      },
      ...(body && { body: JSON.stringify(body) }),
    })

    let parsedJson: any = null
    try {
      parsedJson = await response.json()
    } catch (err) {}
    const result = { body: parsedJson, response }
    if (!result.response.ok) throw new HttpError('HTTP error', result.response.status, result.body)
    return result.body
  } catch (err) {
    if (err instanceof HttpError) {
      console.error('Http error: ', err.body)
    } else {
      console.error('Unknown error: ', err)
    }
    throw err
  }
}

export class HttpError extends Error {
  constructor(message: string, public status: number, public body: any) {
    super(message)
  }
}

export type CreateQueryParams = {
  operator: string
  operatorArguments: Record<string, string | number>
  fields: string[]
}
export function createQuery({ operator, operatorArguments, fields }: CreateQueryParams) {
  const operatorArgumentsString = Object.entries(operatorArguments)
    .map(([key, value]) => `  ${key}: ${value}`)
    .join(',\n')
  const fieldsString = fields.map((v) => `${v}`).join('\n')
  const querySting = `query {\n ${operator}(\n${operatorArgumentsString}) { \n ${fieldsString}}}`

  return gql(querySting)
}

export function filtersToQueryParams(filters: FilterState) {
  const operatorArguments = {
    fromTime: makeDateConsumableForApi(filters.dateFrom),
    toTime: makeDateConsumableForApi(filters.dateTo),
    ...(filters.symbols.length > 0 && { instrumentAliases: filters.symbols.join(',') }),
    ...(filters.journalId != null && { journalIds: filters.journalId }),
  }
  return operatorArguments
}

export function validateErrorResponse(response: unknown): response is { message: string } {
  return (
    response !== null &&
    typeof response === 'object' &&
    'message' in response &&
    typeof response.message === 'string'
  )
}
