import { useCallback } from 'react'
import { useFilteredPnl, useFilteredStatistics } from '../tabs/DashboardTab/hooks'
import { useFilteredTrades } from '../tabs/TradesTableTab/hooks'

export default function useUpdateHomePageData(): () => Promise<any> {
  const { mutate: mutatePnl } = useFilteredPnl()
  const { mutate: mutateTrades } = useFilteredTrades()
  const { mutate: mutateStatistics } = useFilteredStatistics()

  return useCallback(
    () => Promise.all([mutatePnl(), mutateTrades(), mutateStatistics()]) satisfies Promise<any>,
    [mutatePnl, mutateTrades, mutateStatistics]
  )
}
