import { JournalInfo, Note } from 'core/types'
import { ModalController } from 'shared/Modal'
import ModalWindowLayout from 'shared/ModalWindowLayout'
import { JournalInfoGrid } from '../../components/JournalInfoGridFull/JournalInfoGrid'
import { NoteSection } from '../../components/NoteSection/NoteSection'
import { useCallback } from 'react'

type JournalNoteModalProps = {
  controller: ModalController<
    void,
    {
      journal: JournalInfo
      note?: Note
    }
  >
  onUpdate: () => Promise<unknown>
}
export function JournalNoteModal({ controller, onUpdate }: JournalNoteModalProps) {
  if (!controller.data) throw new Error('TradeNoteModal: no data provided')
  const { journal, note } = controller.data

  const createNote: () => Promise<Note> = useCallback(async () => {
    throw new Error('Not implemented')
  }, [])

  return (
    <ModalWindowLayout>
      <ModalWindowLayout.Header onClose={() => controller.close()}>
        Journal Note Modal
      </ModalWindowLayout.Header>
      <ModalWindowLayout.Content>
        <JournalInfoGrid journal={journal} />
        <NoteSection note={note} onNoteCreate={createNote} onUpdate={onUpdate} />
      </ModalWindowLayout.Content>
    </ModalWindowLayout>
  )
}
