import { Note, TradeItem } from 'core/types'
import { TradeInfoGridFull } from 'pages/HomePage/tabs/NotesTab/modals/TradeNoteModal/TradeInfoGridFull/TradeInfoGridFull'
import { ModalController } from 'shared/Modal'
import ModalWindowLayout from 'shared/ModalWindowLayout'
import { NoteSection } from '../../components/NoteSection/NoteSection'
import { createTradeNote } from 'core/api/trades'
import { useCallback } from 'react'

type TradeNoteModalProps = {
  controller: ModalController<
    void,
    {
      trade: TradeItem
      note?: Note
    }
  >
  onUpdate: () => Promise<unknown>
}
export function TradeNoteModal({ controller, onUpdate }: TradeNoteModalProps) {
  if (!controller.data) throw new Error('TradeNoteModal: no data provided')
  const { trade, note } = controller.data

  const createNote = useCallback(async () => {
    const response = await createTradeNote(trade.id)
    return response.data.result satisfies Note
  }, [trade.id])

  // console.log(trade, note)
  return (
    <ModalWindowLayout>
      <ModalWindowLayout.Header onClose={() => controller.close()}>Text</ModalWindowLayout.Header>
      <ModalWindowLayout.Content>
        <TradeInfoGridFull tradeItem={trade} />
        <NoteSection note={note} onNoteCreate={createNote} onUpdate={onUpdate} />
      </ModalWindowLayout.Content>
    </ModalWindowLayout>
  )
}
