import { useJournalsAll } from 'core/hooks/useJournalsAll'
import { filterState } from 'core/store'
import { JournalInfo } from 'core/types'
import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'

export function useSelectedJournal() {
  const [selectedJournal, setSelectedJournal] = useState<JournalInfo | null>(null)
  const [filters] = useRecoilState(filterState)
  const journalsAll = useJournalsAll()

  useEffect(() => {
    if (selectedJournal === null && filters.journalId === '') return
    if (selectedJournal?.id === filters.journalId) return
    setSelectedJournal(
      journalsAll.data?.find((journal) => journal.id === filters.journalId) ?? null
    )
  }, [filters.journalId, journalsAll.data, selectedJournal])

  return selectedJournal
}
