import './TradeInfo.scss'

import { TradeItem } from 'core/types'
import Icon from 'shared/Icon'
import TextMoney from 'shared/TextMoney'

type TradeInfoProps = { trade?: TradeItem | null }
export function TradeInfo({ trade }: TradeInfoProps) {
  if (!trade) return null

  return (
    <div className="TradeInfo">
      <Icon name="ChartCandlestick" />
      <span className="bold">{trade.instrumentAlias}</span>
      <span className={trade.isBuy ? 'color-red' : 'color-green'}>
        {trade.isBuy ? 'Buy' : 'Sell'}
      </span>
      <span>
        {' '}
        Profit <TextMoney value={trade.profit} colored />
      </span>
      <span className="color-secondary-l3">
        Entry <TextMoney value={trade.priceEntry} />
      </span>
      <span className="color-secondary-l3">
        Exit <TextMoney value={trade.priceExit} />
      </span>
    </div>
  )
}
