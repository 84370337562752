import { JournalInfo } from 'core/types'
import { TradeInfoGridCell } from '../../modals/TradeNoteModal/TradeInfoGridFull/TradeInfoGridFull'

type JournalInfoGridProps = {
  journal: JournalInfo
}
export function JournalInfoGrid({ journal }: JournalInfoGridProps) {
  return (
    <div className="JournalInfoGrid">
      <TradeInfoGridCell label="Journal">{journal.journalName}</TradeInfoGridCell>
    </div>
  )
}
