import './HomePage.scss'
import { Outlet } from 'react-router-dom'
import PageLayout from 'shared/PageLayout'
import StaticTabs from './components/StaticTabs/StaticTabs'
import Icon from 'shared/Icon'
import Filters from './components/Filters'
import useHomePageSearchParamsResolver from './hooks/useHomePageSearchParamsResolver'

export function Home() {
  useHomePageSearchParamsResolver()

  return (
    <PageLayout className="HomePage">
      <PageLayout.Header />
      <PageLayout.Content className="HomePage__Content">
        <StaticTabs>
          <StaticTabs.Tab to="/home/dashboard">
            <Icon name="Dashboard" />
            Dashboard
          </StaticTabs.Tab>
          <StaticTabs.Tab to="/home/trades-history">
            <Icon name="Table" />
            Trades History
          </StaticTabs.Tab>
          <StaticTabs.Tab to="/home/notes">
            <Icon name="Note" />
            Notes
          </StaticTabs.Tab>
        </StaticTabs>

        <Filters />

        <div className="tabs-content">
          <Outlet />
        </div>
      </PageLayout.Content>
      <PageLayout.Footer />
    </PageLayout>
  )
}
