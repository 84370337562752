import './NoteMedia.scss'

import { useMediaForNote } from 'core/api/trades'
import { useContext, useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import classNames from 'classnames'
import { AllHTMLAttributes } from 'react'
import { MediaUploadingStatus } from './MediaUploadingStatus/MediaUploadingStatus'
import { NoteSectionContext } from '../NoteSectionContext'
import { useAttachmentUploading } from './useAttachmentUploading.hook'
import { NoteMediaHeader } from './NoteMediaHeader/NoteMediaHeader'
import { NoteMediaGrid } from './NoteMediaGrid'

type NoteMediaProps = AllHTMLAttributes<HTMLDivElement>
export function NoteMedia({ className, ...props }: NoteMediaProps) {
  const { note, onNoteCreate } = useContext(NoteSectionContext)
  const { sort, setSort, sortedMedia, mutateMedia, isLoading } = useSortedMediaForNote(note?.id)
  const { handleFiles, uploadingList } = useAttachmentUploading({
    onMediaUpdate: mutateMedia,
    onTradeUpdate: () => Promise.resolve(),
    onNoteCreate,
    note,
  })

  const { getRootProps, getInputProps, isDragActive, inputRef } = useDropzone({
    onDrop: (files) => {
      handleFiles(files)
      inputRef.current && (inputRef.current.value = '')
    },
    accept: { 'image/jpeg': ['.jpg', '.jpeg'], 'image/png': ['.png'] },
    noClick: true,
  })

  return (
    <section className={classNames('NoteMedia', className)} {...props}>
      {/* <div className="heading text-normal-bold">Attachments</div> */}
      <div className="attachments-section__wrapper">
        <div
          className={classNames('attachments-section', isDragActive ? 'dragged-over' : '')}
          {...getRootProps()}
        >
          <NoteMediaHeader
            onBrowseClick={() => inputRef.current?.click()}
            sort={sort}
            setSort={setSort}
            sortingDisabled={sortedMedia.length === 0 /*|| uploadingList.length > 0*/}
          />
          <MediaUploadingStatus uploadingList={uploadingList} />
          <NoteMediaGrid isLoading={isLoading} items={sortedMedia} onMediaUpdate={mutateMedia} />
          <input {...getInputProps()} />
          <div className="file-drop-overlay">Drop file here</div>
        </div>
      </div>
    </section>
  )
}

function useSortedMediaForNote(noteId?: string | null) {
  const mediaResponse = useMediaForNote(noteId)
  const [sort, setSort] = useState<'asc' | 'desc'>('asc')
  const sortedMedia = useMemo(() => {
    const result = [...(mediaResponse.data?.result ?? [])]
    return result.sort((a, b) => {
      const delta = new Date(a.creationDate).getTime() - new Date(b.creationDate).getTime()
      return delta * (sort === 'asc' ? 1 : -1)
    })
  }, [mediaResponse.data?.result, sort])

  return {
    sortedMedia,
    isLoading: mediaResponse.isLoading,
    sort,
    setSort,
    mutateMedia: mediaResponse.mutate,
  }
}
