import { AxiosError } from 'axios'
import { addMediaToNote } from 'core/api/trades'
import { Note } from 'core/types'
import { useCallback, useMemo, useState } from 'react'
import { useNotifications } from 'shared/Notifications'
import { v4 as uuid } from 'uuid'

const FILE_PARAMS = {
  maxSize: 5 * 1024 * 1024,
  allowedFormats: ['image/jpeg', 'image/png'],
}
export type UploadingItem = {
  file: File
  startTimestamp: number
  progress?: number | null
  id: string
}
type UseAttachmentUploadingConfig = {
  onMediaUpdate: () => Promise<unknown>
  onTradeUpdate: () => Promise<unknown>
  onNoteCreate: () => Promise<Note>
  note?: Note
}
type UseAttachmentUploadingReturn = {
  uploadingList: UploadingItem[]
  handleFiles: (files: (File | null)[]) => void
}

export function useAttachmentUploading({
  onMediaUpdate,
  onTradeUpdate,
  onNoteCreate,
  note,
}: UseAttachmentUploadingConfig): UseAttachmentUploadingReturn {
  const [uploadingMap, setUploadingMap] = useState<{ [id: string]: UploadingItem }>({})
  const uploadingList = useMemo(
    () => Object.values(uploadingMap).sort((a, b) => a.startTimestamp - b.startTimestamp),
    [uploadingMap]
  )
  const { showNotification } = useNotifications()

  const addFileToMap = useCallback(
    (file: File, id: string) => {
      if (uploadingMap[id]) throw new Error('File already exists in uploading map')
      const timestamp = Date.now()
      const entry: UploadingItem = {
        file,
        id,
        startTimestamp: timestamp,
        progress: undefined,
      }

      setUploadingMap((map) => ({ ...map, [id]: entry }))
    },
    [uploadingMap]
  )

  const updateProgress = useCallback((uploadingEntryId: string, progress?: number | null) => {
    setUploadingMap((oldMap) => ({
      ...oldMap,
      [uploadingEntryId]: { ...oldMap[uploadingEntryId], progress } satisfies UploadingItem,
    }))
  }, [])

  const removeFileFromMap = useCallback((uploadingEntryId: string) => {
    setUploadingMap((map) => {
      const { [uploadingEntryId]: _, ...rest } = map
      return rest
    })
  }, [])

  const uploadImage = useCallback(
    async (file: File) => {
      const uploadingEntryId = uuid()

      try {
        addFileToMap(file, uploadingEntryId)
        let currentNote: Note = note ? note : await onNoteCreate()

        await addMediaToNote(currentNote.id, file, (ev) =>
          updateProgress(uploadingEntryId, ev.progress ? ev.progress * 100 : null)
        )
        if (!note) await onTradeUpdate()
        await onMediaUpdate()
      } catch (err) {
        console.error(err)
        if (err instanceof AxiosError && err.response?.data) {
          showNotification({
            text: err.response.data.message ?? 'Failed to upload image',
            type: 'error',
          })
        } else {
          showNotification({
            text: 'Failed to upload image',
            type: 'error',
          })
        }
      } finally {
        removeFileFromMap(uploadingEntryId)
      }
    },
    [
      addFileToMap,
      note,
      onNoteCreate,
      onTradeUpdate,
      onMediaUpdate,
      updateProgress,
      showNotification,
      removeFileFromMap,
    ]
  )

  const handleFiles = useCallback(
    (files: (File | null)[]) => {
      files.forEach((file) => {
        if (!file) return
        if (file.size > FILE_PARAMS.maxSize) {
          return showNotification({
            text: 'File size should be less than 5MB',
            type: 'error',
          })
        }
        if (!FILE_PARAMS.allowedFormats.includes(file.type)) {
          return showNotification({
            text: 'File should be an JPG or PNG image',
            type: 'error',
          })
        }
        uploadImage(file)
      })
    },
    [showNotification, uploadImage]
  )

  return { uploadingList, handleFiles }
}
