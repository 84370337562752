import { ModalController } from 'shared/Modal'
import './CreateTradeModal.scss'
import { ModalLayout } from 'shared/ModalLayout'
import { Button } from 'shared/Button'
import { useForm, UseFormReturn } from 'react-hook-form'
import { useJournals } from 'core/api/journals'
import { TradeItemCreationData } from 'core/types'
import { useCallAndReport } from 'core/hooks/useDoAndReport'
import { createTrade } from 'core/api/trades'
import { useMemo } from 'react'
import { validateErrorResponse } from 'core/utils'
import { useAliases } from 'core/api/dashboard'
import useUpdateHomePageData from 'pages/HomePage/hooks/useUpdateHomePageData'

type TradeOrderType = 'Buy' | 'Sell'

type NewTradeForm = {
  entryDate: string
  entryTime: string
  exitDate: string
  exitTime: string
  entryPrice: number
  exitPrice: number
  orderType: TradeOrderType
  size: number
  instrument: string
  profit: number
  mfe: number
  mae: number
  commission: number
  journalId: string
}

const formValueToTradeCreationData = (data: NewTradeForm): TradeItemCreationData => {
  return {
    timeEntry: new Date(data.entryDate + 'T' + data.entryTime).toISOString(),
    timeExit: new Date(data.exitDate + 'T' + data.exitTime).toISOString(),
    priceEntry: +data.entryPrice,
    priceExit: +data.exitPrice,
    isBuy: data.orderType === 'Buy',
    commission: +data.commission,
    instrumentAlias: data.instrument,
    journalId: data.journalId,
    mae: +data.mae,
    mfe: +data.mfe,
    maxSize: +data.size,
    profit: +data.profit,
  }
}

function useDateTimeLimits(form: UseFormReturn<NewTradeForm>) {
  const entryDateMax = useMemo(() => new Date().toISOString().split('T')[0], [])
  const entryDateValue = form.watch('entryDate')
  const exitDateMin = useMemo(() => {
    return entryDateValue === '' ? '1970-01-01' : entryDateValue
  }, [entryDateValue])
  const exitDateValue = form.watch('exitDate')
  const entryTimeValue = form.watch('entryTime')
  const exitTimeMin = useMemo(() => {
    if (entryDateValue === '' || exitDateValue === '') return '00:00'
    if (entryDateValue !== exitDateValue) return '00:00'
    if (entryTimeValue === '') return '00:00'
    return entryTimeValue
  }, [entryDateValue, entryTimeValue, exitDateValue])

  return { entryDateMax, exitDateMin, exitTimeMin }
}

type CreateModalProps = { controller: ModalController }
export function CreateTradeModal({ controller }: CreateModalProps) {
  const lastSubmit = JSON.parse(localStorage.getItem('LastSubmittedTradeCreationData') ?? 'null')
  const form = useForm<NewTradeForm>({
    defaultValues: {
      entryDate: lastSubmit?.entryDate ?? '',
      exitDate: lastSubmit?.exitDate ?? '',
      instrument: lastSubmit?.instrument ?? '',
      journalId: lastSubmit?.journalId ?? '',
    },
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = form

  const { data: journal } = useJournals()
  const updateHomePageData = useUpdateHomePageData()
  const { callAndReport, isLoading } = useCallAndReport()

  const onSubmit = handleSubmit(async (data) => {
    console.log(data)
    localStorage.setItem('LastSubmittedTradeCreationData', JSON.stringify(data))

    await callAndReport(() => createTrade(formValueToTradeCreationData(data)), {
      OK: 'Trade created',
      DEFAULT_ERR: (resp) =>
        validateErrorResponse(resp) ? resp.message : 'Failed to create trade',
      UNKNOWN_ERR: 'Failed to create trade',
    })
    await updateHomePageData()
    controller.close()
  })

  const { entryDateMax, exitDateMin, exitTimeMin } = useDateTimeLimits(form)
  const { data: aliases } = useAliases()

  return (
    <ModalLayout className="CreateTradeModal">
      <form onSubmit={onSubmit}>
        <ModalLayout.Heading>Add new trade</ModalLayout.Heading>
        <div className="col-2">
          <ModalLayout.FormControl label="Entry Date" isError={!!errors.entryDate}>
            <input
              type="date"
              {...register('entryDate', {
                required: 'Entry date is required',
              })}
              max={entryDateMax}
            />
            {errors.entryDate && (
              <ModalLayout.FormControl.Error>
                {errors.entryDate.message}
              </ModalLayout.FormControl.Error>
            )}
          </ModalLayout.FormControl>
          <ModalLayout.FormControl label="Entry Time" isError={!!errors.entryTime}>
            <input
              type="time"
              {...register('entryTime', {
                required: 'Entry time is required',
              })}
            />
            {errors.entryTime && (
              <ModalLayout.FormControl.Error>
                {errors.entryTime.message}
              </ModalLayout.FormControl.Error>
            )}
          </ModalLayout.FormControl>
        </div>

        <div className="col-2">
          <ModalLayout.FormControl label="Exit Date" isError={!!errors.exitDate}>
            <input
              type="date"
              {...register('exitDate', {
                required: 'Exit date is required',
              })}
              min={exitDateMin}
              max={entryDateMax}
            />
            {errors.exitDate && (
              <ModalLayout.FormControl.Error>
                {errors.exitDate.message}
              </ModalLayout.FormControl.Error>
            )}
          </ModalLayout.FormControl>
          <ModalLayout.FormControl label="Exit Time" isError={!!errors.exitTime}>
            <input
              type="time"
              min={exitTimeMin}
              max="23:59"
              {...register('exitTime', {
                required: 'Exit time is required',
              })}
            />
            {errors.exitTime && (
              <ModalLayout.FormControl.Error>
                {errors.exitTime.message}
              </ModalLayout.FormControl.Error>
            )}
          </ModalLayout.FormControl>
        </div>

        <div className="col-2">
          <ModalLayout.FormControl label="Entry price" isError={!!errors.entryPrice}>
            <input
              type="number"
              step="any"
              {...register('entryPrice', { required: 'Entry price is required' })}
              placeholder="0.00"
            />
            {errors.entryPrice && (
              <ModalLayout.FormControl.Error>
                {errors.entryPrice.message}
              </ModalLayout.FormControl.Error>
            )}
          </ModalLayout.FormControl>
          <ModalLayout.FormControl label="Exit price" isError={!!errors.exitPrice}>
            <input
              type="number"
              step="any"
              {...register('exitPrice', { required: 'Exit price is required' })}
              placeholder="0.00"
            />
            {errors.exitPrice && (
              <ModalLayout.FormControl.Error>
                {errors.exitPrice.message}
              </ModalLayout.FormControl.Error>
            )}
          </ModalLayout.FormControl>
        </div>

        <div className="col-2">
          <div className="col-2">
            <ModalLayout.FormControl label="Order type" isError={!!errors.orderType}>
              <select {...register('orderType', { required: 'Order type is required' })}>
                <option value="Buy">Buy</option>
                <option value="Sell">Sell</option>
              </select>
              {errors.orderType && (
                <ModalLayout.FormControl.Error>
                  {errors.orderType.message}
                </ModalLayout.FormControl.Error>
              )}
            </ModalLayout.FormControl>
            <ModalLayout.FormControl label="Size" isError={!!errors.size}>
              <input
                type="number"
                step="1"
                {...register('size', { required: 'Size is required' })}
                placeholder="0"
              />
              {errors.size && (
                <ModalLayout.FormControl.Error>{errors.size.message}</ModalLayout.FormControl.Error>
              )}
            </ModalLayout.FormControl>
          </div>
          <div className="col-2">
            <ModalLayout.FormControl label="Instrument" isError={!!errors.instrument}>
              <input
                type="text"
                list="country"
                {...register('instrument', { required: 'Instrument is required' })}
                placeholder="ABC/DEH"
              />
              <datalist id="country">
                {aliases?.result?.map((alias) => (
                  <option value={alias} key={alias} />
                ))}
              </datalist>
              {errors.instrument && (
                <ModalLayout.FormControl.Error>
                  {errors.instrument.message}
                </ModalLayout.FormControl.Error>
              )}
            </ModalLayout.FormControl>
            <ModalLayout.FormControl label="Profit" isError={!!errors.profit}>
              <input
                type="number"
                step="any"
                {...register('profit', { required: 'Profit is required' })}
                placeholder="0.00"
              />
              {errors.profit && (
                <ModalLayout.FormControl.Error>
                  {errors.profit.message}
                </ModalLayout.FormControl.Error>
              )}
            </ModalLayout.FormControl>
          </div>
        </div>

        <div className="col-2">
          <div className="col-2">
            <ModalLayout.FormControl label="MFE" isError={!!errors.mfe}>
              <input
                type="number"
                step="1"
                {...register('mfe', { required: 'MFE is required' })}
                placeholder="0"
              />
              {errors.mfe && (
                <ModalLayout.FormControl.Error>{errors.mfe.message}</ModalLayout.FormControl.Error>
              )}
            </ModalLayout.FormControl>
            <ModalLayout.FormControl label="MAE" isError={!!errors.mae}>
              <input
                type="number"
                step="1"
                {...register('mae', { required: 'MAE is required' })}
                placeholder="0"
              />
              {errors.mae && (
                <ModalLayout.FormControl.Error>{errors.mae.message}</ModalLayout.FormControl.Error>
              )}
            </ModalLayout.FormControl>
          </div>

          <ModalLayout.FormControl label="Commission (optional)" isError={!!errors.commission}>
            <input type="number" step="any" {...register('commission')} placeholder="0.00" />
            {errors.commission && (
              <ModalLayout.FormControl.Error>
                {errors.commission.message}
              </ModalLayout.FormControl.Error>
            )}
          </ModalLayout.FormControl>
        </div>

        <ModalLayout.FormControl label="Journal" isError={!!errors.journalId}>
          <select {...register('journalId')}>
            {journal?.map((journal) => (
              <option value={journal.id} key={journal.id}>
                {journal.journalName}
              </option>
            ))}
          </select>
          {errors.journalId && (
            <ModalLayout.FormControl.Error>
              {errors.journalId.message}
            </ModalLayout.FormControl.Error>
          )}
        </ModalLayout.FormControl>

        <ModalLayout.Buttons>
          <Button onClick={() => controller.close()}>Cancel</Button>
          <Button color="primary" isLoading={isLoading}>
            Add
          </Button>
        </ModalLayout.Buttons>
      </form>
    </ModalLayout>
  )
}
