import { usePnl, useStatistics } from 'core/api/dashboard'
import { filterState } from 'core/store'
import { useRecoilState } from 'recoil'

export function useFilteredStatistics() {
  const [filters] = useRecoilState(filterState)
  return useStatistics(filters)
}

export function useFilteredPnl() {
  const [filters] = useRecoilState(filterState)
  return usePnl(filters)
}
