import { Note } from 'core/types'
import { createContext, useMemo } from 'react'

type NoteSectionContextType = {
  note?: Note
  onNoteCreate: () => Promise<Note>
  onUpdate: () => Promise<unknown>
}
export const NoteSectionContext = createContext<NoteSectionContextType>({
  onNoteCreate: async () => {
    throw new Error('NoteSectionContextProvider not found')
  },
  onUpdate: async () => {
    throw new Error('NoteSectionContextProvider not found')
  },
})

type NoteSectionContextProviderProps = {
  children: React.ReactNode
  note?: Note
  onNoteCreate: () => Promise<Note>
  onUpdate: () => Promise<unknown>
}
export function NoteSectionContextProvider({
  children,
  onNoteCreate,
  onUpdate,
  note,
}: NoteSectionContextProviderProps) {
  const value = useMemo(() => ({ note, onNoteCreate, onUpdate }), [note, onNoteCreate, onUpdate])

  return <NoteSectionContext.Provider value={value}>{children}</NoteSectionContext.Provider>
}
