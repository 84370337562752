import { Modal, ModalController, useModal } from 'shared/Modal'
import './MediaEntry.scss'
import { MediaItem } from 'core/types'
import { useRef } from 'react'
import { useTrackImgElementLoading } from 'core/hooks/useTrackImgElementLoading'
import classNames from 'classnames'
import { Button } from 'shared/Button'
import Icon from 'shared/Icon'
import SkeletonBlock from 'shared/SkeletonBlock'

type MediaEntryProps = {
  mediaItem: MediaItem
  hasDeleteButton?: boolean
  stopPropagation?: boolean
  onDelete?: () => void
}
export function MediaEntry({
  mediaItem,
  hasDeleteButton,
  stopPropagation,
  onDelete,
}: MediaEntryProps) {
  const previewModal = useModal()
  const thumbnailImgRef = useRef<HTMLImageElement>(null)
  const isThumbnailLoading = useTrackImgElementLoading(thumbnailImgRef)

  const handleDeleteClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    onDelete && onDelete()
  }

  const handleBaseClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (stopPropagation) event.stopPropagation()
    previewModal.open()
  }

  return (
    <>
      <div
        className={classNames('MediaEntry', isThumbnailLoading && 'loading')}
        onClick={handleBaseClick}
      >
        <SkeletonBlock className="thumbnail-skeleton" />
        {hasDeleteButton === true && (
          <Button square size="small" className="delete" onClick={handleDeleteClick}>
            <Icon name="Trash" />
          </Button>
        )}

        <div className="hover-card">
          <Icon name="ZoomIn" width="24px" height="24px" />
        </div>
        <img
          ref={thumbnailImgRef}
          src={mediaItem.thumbnailSignedUrl}
          alt="thumbnail"
          className="thumbnail"
        />
      </div>
      <MediaEntryFullViewModal controller={previewModal} mediaItem={mediaItem} />
    </>
  )
}

type ImageItemProps = { mediaItem: MediaItem; onUpdate: () => Promise<unknown> }
export function Attachment({ mediaItem, onUpdate }: ImageItemProps) {}

type MediaEntryFullViewModalProps = { controller: ModalController; mediaItem: MediaItem }
function MediaEntryFullViewModal({ controller, mediaItem }: MediaEntryFullViewModalProps) {
  return (
    <Modal controller={controller} stopPropagation={true}>
      <div className="MediaEntryFullViewModal">
        <img src={mediaItem.thumbnailSignedUrl} alt="thumbnail" className="thumbnail" />
        <img src={mediaItem.signedUrl} className="full" alt="Full" />
        <Button
          className="close-button"
          square
          size="large"
          appearance="menu-item"
          onClick={() => controller.close()}
        >
          <Icon name="Cross" width="24px" height="24px" />
        </Button>
      </div>
    </Modal>
  )
}
