import './MediaRow.scss'

import classNames from 'classnames'
import { useMediaForNote } from 'core/api/trades'
import { MediaItem } from 'core/types'
import { useMemo } from 'react'
import MediaEntry from 'shared/MediaEntry'

type MediaRowProps = { noteId: string; mediaCount: number }
export function MediaRow({ noteId, mediaCount }: MediaRowProps) {
  const media = useMediaForNote(mediaCount > 0 ? noteId : null)
  const { visibleMedia, hiddenMediaCount } = useSlicedMedia(media.data?.result ?? [])

  if (mediaCount === 0) return null

  return (
    <div className={classNames('MediaRow', { loading: media.isLoading })}>
      {visibleMedia.map((media) => (
        <MediaEntry key={media.key} mediaItem={media} stopPropagation />
      ))}
      {hiddenMediaCount > 0 && <div className="more-available">+{hiddenMediaCount}</div>}
    </div>
  )
}

type UseSlicedMediaResponse = {
  visibleMedia: MediaItem[]
  hiddenMediaCount: number
}
function useSlicedMedia(mediaItems: MediaItem[]): UseSlicedMediaResponse {
  return useMemo(() => {
    let visibleMedia: MediaItem[] = []
    let hiddenMediaCount = 0

    if (mediaItems.length > 4) {
      visibleMedia = mediaItems.slice(0, 3)
      hiddenMediaCount = mediaItems.length - 4
    } else if (mediaItems.length <= 4) {
      visibleMedia = mediaItems
    }

    return { visibleMedia, hiddenMediaCount }
  }, [mediaItems])
}
