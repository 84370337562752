import { Controller, UseFormReturn } from 'react-hook-form'
import { SimpleDropdown } from 'shared/Dropdown'
import classNames from 'classnames'
import { JOURNALS_TYPE_NAME_MAP } from 'core/journalsNameMap'
import { ModalLayout } from 'shared/ModalLayout'
import { Button } from 'shared/Button'
import { useJournalTypes } from 'core/api/journals'

export type EditJournalFormData = {
  journalName: string
  journalType: string
}

type EditJournalFormProps = {
  useFormReturn: UseFormReturn<EditJournalFormData, any, undefined>
  occupiedName: string | null
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>
  onCancel: () => void
  loading: boolean
  actionText: string
  disableTypeDropdown?: boolean
}
export function EditJournalForm({
  useFormReturn: {
    control,
    register,
    formState: { errors, isValid, isSubmitted },
  },
  onSubmit,
  onCancel,
  occupiedName,
  loading,
  actionText,
  disableTypeDropdown,
}: Readonly<EditJournalFormProps>) {
  const journalTypes = useJournalTypes()

  return (
    <ModalLayout>
      <form onSubmit={onSubmit}>
        <ModalLayout.Heading> {actionText} journal </ModalLayout.Heading>

        <ModalLayout.FormControl label="Journal name">
          <input
            {...register('journalName', {
              required: 'Journal name is required',
              validate: (v) => v !== occupiedName || 'Journal name is already taken',
            })}
            id="journal-name"
            type="text"
            className={classNames({
              input: true,
              error: errors.journalName,
            })}
            placeholder="Journal name"
          />
          {errors.journalName && (
            <ModalLayout.FormControl.Error>
              {errors.journalName.message}
            </ModalLayout.FormControl.Error>
          )}
        </ModalLayout.FormControl>

        <ModalLayout.FormControl label="Journal type">
          {journalTypes.isLoading ? (
            <SimpleDropdown className="input" disabled placeholder="Loading..."></SimpleDropdown>
          ) : (
            <Controller
              control={control}
              name="journalType"
              rules={{ required: 'Journal type is required' }}
              render={({ field: { onBlur, onChange, value } }) => (
                <SimpleDropdown
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  id="journal-type"
                  className={classNames({
                    input: true,
                    error: errors.journalType,
                  })}
                  getVisualValue={(v) => JOURNALS_TYPE_NAME_MAP[v] ?? v}
                  placeholder="Journal type"
                  disabled={disableTypeDropdown}
                >
                  {journalTypes.data
                    ?.filter((t) => t.canBeCreatedByUser)
                    .map((jType) => (
                      <SimpleDropdown.Item value={jType.typeName} key={jType.typeName}>
                        {JOURNALS_TYPE_NAME_MAP[jType.typeName] ?? jType.typeName}
                      </SimpleDropdown.Item>
                    ))}
                </SimpleDropdown>
              )}
            />
          )}
          {errors.journalType && (
            <ModalLayout.FormControl.Error>
              {errors.journalType.message}
            </ModalLayout.FormControl.Error>
          )}
        </ModalLayout.FormControl>

        <ModalLayout.Buttons>
          <Button onClick={() => onCancel()} type="button" appearance="solid" color="secondary">
            Cancel
          </Button>
          <Button
            type="submit"
            appearance="solid"
            color="primary"
            isLoading={loading}
            disabled={!isValid}
          >
            {actionText}
          </Button>
        </ModalLayout.Buttons>
      </form>
    </ModalLayout>
  )
}
