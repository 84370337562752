import { useCallback } from 'react'
import { handleSearchParamsForFilters, handleSearchParamsForJournal } from './utils'
import { JournalInfo } from 'core/types'
import { useRecoilState, useRecoilValue } from 'recoil'
import { filterState } from 'core/store'
import { filterQueryParamsSelector } from 'core/store/filter/filter.state'

export function useFilters() {
  const [filters, setFilters] = useRecoilState(filterState)
  const filtersSearchParams = useRecoilValue(filterQueryParamsSelector)

  const setFiltersBySearchParams = useCallback(
    (searchParams: URLSearchParams) => handleSearchParamsForFilters(searchParams, setFilters),
    [setFilters]
  )

  const setJournalBySearchParams = useCallback(
    (searchParams: URLSearchParams, journals: JournalInfo[]) =>
      handleSearchParamsForJournal(searchParams, journals, setFilters),
    [setFilters]
  )

  return {
    filters,
    setFilters,
    setFiltersBySearchParams,
    setJournalBySearchParams,
    filtersSearchParams,
  }
}
