import { createContext } from 'react'
import { TradesTableSectionContextData } from './models'

export const TradesTableTabContext = createContext<TradesTableSectionContextData>({
  selectedTrade: null,
  isColumSelectedMap: {
    commission: false,
    fills: false,
    instrumentAlias: false,
    isBuy: false,
    mae: false,
    mfe: false,
    maxSize: false,
    postMae: false,
    postMfe: false,
    priceEntry: false,
    priceExit: false,
    profit: false,
    timeEntry: false,
    timeExit: false,
  },
  columns: [],
  setColumnsKeys: () => {},

  openAddNoteModal: () => {},
  openTradeInfoModal: () => {},
  openMoveTradeToModal: () => Promise.resolve(void 0),
  openConfirmDeleteTradeModal: () => {},
  openTableConfigModal: () => {},
})
