import './NoteCard.scss'

import { JournalInfo, Note } from 'core/types'
import { MediaRow } from './MediaRow/MediaRow'
import TextDate from 'shared/TextDate'
import { TradeInfo } from './TradeInfo/TradeInfo'
import { AllHTMLAttributes } from 'react'
import classNames from 'classnames'

type NoteItemProps = { journal?: JournalInfo; note: Note } & AllHTMLAttributes<HTMLDivElement>
export function NoteItem({ journal, note, className, ...props }: NoteItemProps) {
  console.log('Note card got note\n', note)
  return (
    <div className={classNames('NoteItem', className)} {...props}>
      <div className="header">
        <div className="journal-name text-big-bold">
          {journal?.journalName ?? 'Unknown journal'}
        </div>
        <div className="creation-date color-secondary-l3">
          <TextDate date={new Date(note.creationDate)} />
        </div>
      </div>
      <TradeInfo trade={note.relatedTrade} />
      <div className="content">
        <div className="text">{note.noteText}</div>
      </div>
      <MediaRow noteId={note.id} mediaCount={note.mediaCount} />
    </div>
  )
}
