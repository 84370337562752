import { FilterState } from 'core/contexts/FilterContext'
import { MetricsCellConfig } from 'core/types'
import { createQuery } from 'core/utils'
import { useMemo } from 'react'

export function useGetBarDiagramQuery(
  filters: FilterState,
  config: MetricsCellConfig,
  zoneOffset = 0
) {
  const serializedArguments = useMemo(
    () => ({
      ...(filters.journalId && { journalIds: `["${filters.journalId}"]` }),
      ...(filters.symbols.length > 0 && {
        instrumentAliases: `[${filters.symbols.map((v) => `"${v}"`).join(', ')}]`,
      }),
      fromTime: `"${filters.dateFrom.toISOString().split('Z')[0]}"`,
      toTime: `"${filters.dateTo.toISOString().split('Z')[0]}"`,
      ...config,
      zoneOffset,
    }),
    [config, filters, zoneOffset]
  )
  return useMemo(
    () =>
      createQuery({
        operator: 'getBarDiagram',
        operatorArguments: serializedArguments,
        fields: ['classifier', 'value'],
      }),
    [serializedArguments]
  )
}
