import './NotesTab.scss'

import { useRecoilState } from 'recoil'
import { filterState } from 'core/store'
import { useJournals, useNotes } from 'core/api/journals'
import { JournalInfo, Note, TradeItem } from 'core/types'
import { useMemo } from 'react'
import { NoteItem } from './components/NoteCard/NoteCard'
import { Modal, useModal } from 'shared/Modal'
import { TradeNoteModal } from './modals/TradeNoteModal/TradeNoteModal'
import { JournalNoteModal } from './modals/JournalNoteModal/JournalNoteModal'

function useJournalsMap() {
  const journals = useJournals()
  const journalsMap = useMemo(() => {
    const map = new Map<string, JournalInfo>()
    if (!journals.data) return map
    journals.data.forEach((journal) => map.set(journal.id, journal))
    return map
  }, [journals.data])
  return journalsMap
}

// type NotesTabProps = {}
export function NotesTab() {
  const [filters] = useRecoilState(filterState)
  const response = useNotes({
    journalIds: filters.journalId ? [filters.journalId] : [],
    includeJournalNotes: true,
    includeTradeNotes: true,
  })
  const journalsMap = useJournalsMap()

  const tradeNoteModal = useModal<void, { trade: TradeItem; note?: Note }>()
  const journalNoteModal = useModal<void, { journal: JournalInfo; note?: Note }>()

  const openModal = (note: Note) => {
    if (note.relatedTrade) {
      tradeNoteModal.open({ trade: note.relatedTrade, note })
    } else {
      const journal = journalsMap.get(note.journalId)
      if (!journal) throw new Error('Journal not found. Cannot open note modal')
      journalNoteModal.open({ journal, note })
    }
  }

  return (
    <div className="NotesTab">
      <div className="notes-wrapper">
        {response.data?.result.map((note: Note) => (
          <NoteItem
            key={note.id}
            journal={journalsMap.get(note.journalId)}
            note={note}
            onClick={() => openModal(note)}
          />
        ))}
      </div>
      <Modal controller={tradeNoteModal}>
        <TradeNoteModal controller={tradeNoteModal} onUpdate={response.mutate} />
      </Modal>
      <Modal controller={journalNoteModal}>
        <JournalNoteModal controller={journalNoteModal} onUpdate={response.mutate} />
      </Modal>
    </div>
  )
}
